import Network from "../helpers/network";

export const CarRentalAPI = {
    fetchCarRentalPanigtion: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/car_rental?page=${page}&page_size=${limit}${filter}`);
        return response;
    },
    fetchCarRental: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/car_rental?page=${page}&page_size=${limit}${filter}`);
        return response.data;
    },
    fetchCarRentalByID: async ({id}) => {
        const response = await Network.get(`/v1/public/car_rental/${id}`);
        return response.data;
    },
    fetchCategory: async () => {
        const response = await Network.get(`/v1/public/car_rental/category`);
        return response.data;
    },
}