import React, { useState, useEffect, useRef  } from 'react';
import { Link } from "react-router-dom";

import logoDark from "../assets/images/hpt-dark.png"
import logoLight from "../assets/images/hpt-white.png"
import logoWhite from "../assets/images/hpt-white.png"

import Language from './language';
import i18n from '../i18n'
import { DataAPI } from '../Api/data';

export default function Navbar({navclass, navlight, manuclass}){
    let [scrolling, setScrolling] = useState(false);
    let [isToggle, setToggle] = useState(false);
    let [manu , setManu] = useState('');
    let [subManu , setSubManu] = useState('');
    let [countryMenu , setCountryMenu] = useState([]);
    
    useEffect(()=>{
        const handleScroll = () => {
            const isScrolling = window.scrollY > 50;
            setScrolling(isScrolling);
        };

        window.addEventListener('scroll', handleScroll);
        
        
        let current = window.location.pathname
        setManu(current)
        setSubManu(current)
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', handleScroll);
          };
    },[])

    const toggleMenu = () =>{
        setToggle(!isToggle)
    }

    React.useEffect(()=>{
        DataAPI.fetchCountryMenu().then((resp) => {
            setCountryMenu(resp);
        })
    },[])

    React.useEffect(()=>{
        console.log(subManu);
    },[subManu])


    return(
        <nav id="topnav" className={`${navclass} ${scrolling ? 'nav-sticky' : ''}`}>
            <div className="container relative">
                {navlight && (
                    <Link className="logo" to="/">
                        <span className="inline-block dark:hidden">
                            <img src={logoDark} style={{width:32, height: 35}}  className="l-light" alt=""/>
                            <img src={logoDark} style={{width:32, height: 35}}  className="l-dark" alt=""/>
                            &nbsp;<font color="black" >HappyTrip</font>
                        </span>
                        
                        
                        <span className="inline-block hidden dark:inline-block">
                            <img src={logoWhite} style={{width:32, height: 35}} className="hidden dark:inline-block" alt=""/>
                            &nbsp;<font color="white" >HappyTrip</font>
                        </span>
                    </Link>
                )}
                {!navlight && (
                    <Link className="logo" to="/">
                        <div>
                            <img src={logoDark} width={32} className="h-7 inline-block dark:hidden" alt=""/>
                            <img src={logoWhite} width={32} className="h-7 hidden dark:inline-block" alt=""/>
                        </div>
                    </Link>
                )}

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className={`navbar-toggle ${isToggle ? 'open' : ''}`} id="isToggle" onClick={() =>toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <ul className="buy-button list-none mb-0 space-x-1">
                    <Language />
                </ul>

                <div id="navigation" style={{display: isToggle === true ? 'block' : 'none'}}>
                    <ul className={`navigation-menu ${manuclass}`}>
                        <li className={`${manu === '/' ? 'active' : '' }`}><Link to="/" className="sub-menu-item">{i18n.t("home")}</Link></li>

                        <li className={`has-submenu parent-parent-menu-item ${['/tour', countryMenu.map(((item,index) => '/tour'+index)) ].includes(manu) ? 'active' : '' }`}>
                            <Link to="" onClick={()=>setSubManu(subManu === '/tour' ? '' : '/tour' )}>{i18n.t("tour")}</Link>
                            <span className="menu-arrow"></span>
                            <ul className={`submenu ${['/tour'].includes(subManu) ? 'open' : '' }`}>
                                <li><Link to="/tour" className="sub-menu-item">{i18n.t("all")}</Link></li>
                                {
                                    countryMenu.map((item,index) => <li className={`has-submenu parent-menu-item`} key={'country'+index}>
                                        <Link to="" onClick={()=>setSubManu(subManu === '/tour'+index ? '' : '/tour'+index)}>{item.name}</Link><span className="submenu-arrow"></span>
                                        <ul className={`submenu ${['/tour'+index].includes(subManu) ? 'open' : ''}`}>
                                            <li><Link to={`/tour?country_id=${item.id}`} className="sub-menu-item">{i18n.t("all")}</Link></li>
                                            {item?.provinces?.map((subItem, subIndex)=> {
                                                return <li key={'provinces'+subIndex} ><Link to={`/tour?country_id=${item.id}&province_id=${subItem.id}`} className="sub-menu-item">{subItem.name}</Link></li>
                                            })}
                                        </ul>
                                    </li>)
                                }
                            </ul>
                        </li>

                        <li className={`has-submenu parent-parent-menu-item ${['/ticket', countryMenu.map(((item,index) => '/ticket'+index)) ].includes(manu) ? 'active' : '' }`}>
                            <Link to="" onClick={()=>setSubManu(subManu === '/ticket' ? '' : '/ticket' )}>{i18n.t("ticket")}</Link>
                            <span className="menu-arrow"></span>
                            <ul className={`submenu ${['/ticket'].includes(subManu) ? 'open' : '' }`}>
                                <li><Link to="/ticket" className="sub-menu-item">{i18n.t("all")}</Link></li>
                                {
                                    countryMenu.map((item,index) => <li className={`has-submenu parent-menu-item`} key={'country'+index}>
                                        <Link to="" onClick={()=>setSubManu(subManu === '/ticket'+index ? '' : '/ticket'+index)}>{item.name}</Link><span className="submenu-arrow"></span>
                                        <ul className={`submenu ${['/ticket'+index].includes(subManu) ? 'open' : ''}`}>
                                            <li><Link to={`/ticket?country_id=${item.id}`} className="sub-menu-item">{i18n.t("all")}</Link></li>
                                            {item?.provinces?.map((subItem, subIndex)=> {
                                                return <li key={'provinces'+subIndex} ><Link to={`/ticket?country_id=${item.id}&province_id=${subItem.id}`} className="sub-menu-item">{subItem.name}</Link></li>
                                            })}
                                        </ul>
                                    </li>)
                                }
                            </ul>
                        </li>

                        <li className={`has-submenu parent-parent-menu-item ${['/car-rental', countryMenu.map(((item,index) => '/car-rental'+index)) ].includes(manu) ? 'active' : '' }`}>
                            <Link to="" onClick={()=>setSubManu(subManu === '/car-rental' ? '' : '/car-rental' )}>{i18n.t("car_rental")}</Link>
                            <span className="menu-arrow"></span>
                            <ul className={`submenu ${['/car-rental'].includes(subManu) ? 'open' : '' }`}>
                                <li><Link to="/car-rental" className="sub-menu-item">{i18n.t("all")}</Link></li>
                                {
                                    countryMenu.map((item,index) => <li className={`has-submenu parent-menu-item`} key={'country'+index}>
                                        <Link to="" onClick={()=>setSubManu(subManu === '/car-rental'+index ? '' : '/car-rental'+index)}>{item.name}</Link><span className="submenu-arrow"></span>
                                        <ul className={`submenu ${['/car-rental'+index].includes(subManu) ? 'open' : ''}`}>
                                            <li><Link to={`/car-rental?country_id=${item.id}`} className="sub-menu-item">{i18n.t("all")}</Link></li>
                                            {item?.provinces?.map((subItem, subIndex)=> {
                                                return <li key={'provinces'+subIndex} ><Link to={`/car-rental?country_id=${item.id}&province_id=${subItem.id}`} className="sub-menu-item">{subItem.name}</Link></li>
                                            })}
                                        </ul>
                                    </li>)
                                }
                            </ul>
                        </li>

                        {/* <li className={`has-submenu parent-parent-menu-item ${['/grid','/grid-left-sidebar','/grid-right-sidebar','/list','/list-left-sidebar','/list-right-sidebar','/tour-detail-one','/tour-detail-two','/listing-item','/grid-item','/list-item','/detail-item'].includes(manu) ? 'active' : '' }`}><Link to="" onClick={()=>setSubManu(subManu === '/listing-item' ? '' : '/listing-item' )}> Listing </Link><span className="menu-arrow"></span>
                            <ul className={`submenu ${['/grid','/grid-left-sidebar','/grid-right-sidebar','/list','/list-left-sidebar','/list-right-sidebar','/tour-detail-one','/tour-detail-two','/listing-item','/grid-item','/list-item','/detail-item'].includes(subManu) ? 'open' : '' }`}>
                                <li className={`has-submenu parent-menu-item ${['/grid','/grid-left-sidebar','/grid-right-sidebar','/grid-item'].includes(manu) ? 'active' : ''}`}>
                                    <Link to="" onClick={()=>setSubManu(subManu === '/grid-item' ? '' : '/grid-item')}>Tour Grid </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${['/grid','/grid-left-sidebar','/grid-right-sidebar','/grid-item'].includes(subManu) ? 'open' : ''}`}>
                                        <li className={`${manu === '/grid' ? 'active' : '' }`}><Link to="/grid" className="sub-menu-item">Grid</Link></li>
                                        <li className={`${manu === '/grid-left-sidebar' ? 'active' : '' }`}><Link to="/grid-left-sidebar" className="sub-menu-item">Grid Left Sidebar</Link></li>
                                        <li className={`${manu === '/grid-right-sidebar' ? 'active' : '' }`}><Link to="/grid-right-sidebar" className="sub-menu-item">Grid Right Sidebar</Link></li>
                                    </ul>  
                                </li>

                                <li className={`has-submenu parent-menu-item ${['/list-item','/list','/list-left-sidebar','/list-right-sidebar'].includes(manu) ? 'active' : ''}`}>
                                    <Link to="" onClick={()=>setSubManu(subManu === '/list-item' ? '' : '/list-item')}>Tour List </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${['/list-item','/list','/list-left-sidebar','/list-right-sidebar'].includes(subManu) ? 'open' : ''}`}>
                                        <li className={`${manu === '/list' ? 'active' : '' }`}><Link to="/list" className="sub-menu-item">List</Link></li>
                                        <li className={`${manu === '/list-left-sidebar' ? 'active' : '' }`}><Link to="/list-left-sidebar" className="sub-menu-item">List Left Sidebar</Link></li>
                                        <li className={`${manu === '/list-right-sidebar' ? 'active' : '' }`}><Link to="/list-right-sidebar" className="sub-menu-item">List Right Sidebar</Link></li>
                                    </ul>  
                                </li>

                                <li className={`has-submenu parent-menu-item ${['/detail-item','/tour-detail-one','/tour-detail-two'].includes(manu) ? 'active' : ''}`}>
                                    <Link to="" onClick={()=>setSubManu(subManu === '/detail-item' ? '' : '/detail-item')}> Tour Detail </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${['/detail-item','/tour-detail-one','/tour-detail-two'].includes(subManu) ? 'open' : ''}`}>
                                        <li className={`${manu === '/tour-detail-one' ? 'active' : '' }`}><Link to="/tour-detail-one" className="sub-menu-item">Tour Detail One</Link></li>
                                        <li className={`${manu === '/tour-detail-two' ? 'active' : '' }`}><Link to="/tour-detail-two" className="sub-menu-item">Tour Detail Two</Link></li>
                                    </ul>  
                                </li>
                            </ul>
                        </li>
                         */}
                        <li className={`${manu === '/blogs' ? 'active' : '' }`}><Link to="/blogs" className="sub-menu-item">{i18n.t("blog")}</Link></li>
                        <li className={`${manu === '/aboutus' ? 'active' : '' }`}><Link to="/aboutus" className="sub-menu-item">{i18n.t("aboutus")}</Link></li>
                        <li className={`${manu === '/contact' ? 'active' : '' }`}><Link to="/contact" className="sub-menu-item">{i18n.t("contactus")}</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}