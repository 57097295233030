import Network from "../helpers/network";

export const TourAPI = {
    fetchTourPanigtion: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/tour?page=${page}&page_size=${limit}${filter}`);
        return response;
    },
    fetchTour: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/tour?page=${page}&page_size=${limit}${filter}`);
        return response.data;
    },
    fetchTourByID: async ({id}) => {
        const response = await Network.get(`/v1/public/tour/${id}`);
        return response.data;
    },
    fetchCategory: async () => {
        const response = await Network.get(`/v1/public/tour/category`);
        return response.data;
    },
    fetchReviews: async () => {
        const response = await Network.get(`/v1/public/reviews`);
        return response.data;
    },
}