import React from "react"
import {ContactAPI } from "../../Api/contact"

export default function ViewModel(){
    const [contact, setContact] = React.useState({});

    React.useEffect(()=>{
        ContactAPI.fetchContact().then((resp)=>setContact(resp));
    },[])
    
    return {
        contact,
    }
}