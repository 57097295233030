import Network from "../helpers/network";

export const ContactAPI = {
    fetchContact: async () => {
        const response = await Network.get(`/v1/public/contact`);
        return response.data;
    },
    fetchAbouUS: async () => {
        const response = await Network.get(`/v1/public/about_us`);
        return response.data;
    },
    fetchTeam: async () => {
        const response = await Network.get(`/v1/public/team`);
        return response.data;
    },
}